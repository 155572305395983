html,body,div,ul,ol,li,dl,dt,dd,h1,h2,h3,h4,h5,h6,pre,form,p,blockquote,fieldset,input { margin: 0; padding: 0; }
h1,h2,h3,h4,h5,h6,pre,code,address,caption,cite,code,em,strong,th { font-size: 1em; font-weight: normal; font-style: normal; }
ul,ol { list-style: none; }
fieldset,img { border: none; }
caption,th { text-align: left; }
table { border-collapse: collapse; border-spacing: 0; }
object{ outline: 0; }
strong, b, strong *, b * { font-weight: bold; }
em, i, em *, i * { font-style: normal !important; }
a:focus, input:focus{ outline-style: none; }
textarea{ outline: none; }
*{ box-sizing: border-box; }

/* Fonts */
@font-face { font-family: 'clarika'; src: url(./fonts/ClarikaProGrot-Rg.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika-bold'; src: url(./fonts/ClarikaProGeo-Bd.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika'; src: url(./fonts/ClarikaProGeo-Bd.woff) format('woff'); font-weight: bold; font-style: normal; }
@font-face { font-family: 'clarika-geo'; src: url(./fonts/ClarikaProGeo-Rg.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika-light'; src: url(./fonts/ClarikaProGeo-Lt.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika-dm'; src: url(./fonts/ClarikaProGeo-Dm.woff) format('woff'); font-weight: normal; font-style: normal; }

/* Elements */

a{ color: inherit; text-decoration: none; }
.avatar{ width: 32px; height: 32px; display: flex; text-transform: uppercase; font-family: 'clarika-bold'; font-size: 18px; align-items: center; justify-content: center; border-radius: 16px; color: #fff; line-height: 1; background: #666; }
.caps{ text-transform: uppercase; font-family: 'clarika-dm'; font-size: 12px; line-height: 1; letter-spacing: 1px; color: #333; }
.shadow{ box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5); }

/* Units */

.mt-1{ margin-top: 8px; }
.mt-2{ margin-top: 12px; }
.mt-3{ margin-top: 18px; }
.mt-4{ margin-top: 24px; }
.mt-5{ margin-top: 32px; }
.mt-6{ margin-top: 36px; }
.mt-7{ margin-top: 48px; }
.mt-8{ margin-top: 64px; }
.mt-9{ margin-top: 72px; }
.mt-10{ margin-top: 128px; }

.mb-1{ margin-bottom: 8px; }
.mb-2{ margin-bottom: 12px; }
.mb-3{ margin-bottom: 18px; }
.mb-4{ margin-bottom: 24px; }
.mb-5{ margin-bottom: 32px; }
.mb-6{ margin-bottom: 36px; }
.mb-7{ margin-bottom: 48px; }
.mb-8{ margin-bottom: 64px; }
.mb-9{ margin-bottom: 72px; }
.mb-10{ margin-bottom: 128px; }

.pt-1{ padding-top: 8px; }
.pt-2{ padding-top: 12px; }
.pt-3{ padding-top: 18px; }
.pt-4{ padding-top: 24px; }
.pt-5{ padding-top: 32px; }
.pt-6{ padding-top: 36px; }
.pt-7{ padding-top: 48px; }
.pt-8{ padding-top: 64px; }
.pt-9{ padding-top: 72px; }
.pt-10{ padding-top: 128px; }

/* Headings & Text */

.text{ color: #303D72; font-family: 'clarika'; }
  .text a{ color: #4E28DB; }
  .text a:hover{ text-decoration: underline; }
  .text strong{ font-family: 'clarika-bold'; }
  .text b{ font-family: 'clarika-bold'; }

  .client .todo .text ul, .client .todo .text ol{ padding-left: 24px; }
  .client .todo .text ul li{ list-style-type: disc; list-style-position: outside; margin-top: 4px; }
  .client .todo .text ol li{ list-style-type: decimal; list-style-position: outside; margin-top: 4px; }
  .client .todo .text ol li:first-child, .client .todo .text ul li:first-child{ margin-top: 0; }

.h1, .h2, .h3, .h4, .h5, .h6{ font-family: 'clarika-geo'; line-height: 1.3 }

.h1{ font-size: 30px; }
.h2{ font-size: 28px; }
.h3{ font-size: 24px; }
.h4{ font-size: 18px; line-height: 1.4; }

.link{ color: #4E28DB; }
  .link:hover{ text-decoration: underline; }


/* Layout */

body{ font-family: 'clarika', sans-serif; padding: 0; background: #FCFCFC; padding-bottom: 128px; }

.width{ padding-left: 32px; padding-right: 32px; }

.grid{ display: grid; grid-gap: 32px; }
    .grid.narrow-h{ grid-row-gap: 24px; }
    .grid.narrow{ grid-gap: 16px; }
    .grid.no-gap{ grid-gap: 0; }
    .grid-2{ grid-template-columns: 1fr 1fr; }
    .grid-3{ grid-template-columns: 1fr 1fr 1fr; }
    .grid-4{ grid-template-columns: 1fr 1fr 1fr 1fr; }
    .grid-3-1{ grid-template-columns: 3fr 1fr; }


/* Box */
.box{ border: #E9E9EA 1px solid; border-radius: 8px; background: #fff; }

/* Errors */
.errors{ color: #B7424C; padding: 16px 24px; background: #F4D0D3; border-radius: 8px; }

/* Spinner */
.spinner { display: inline-block; width: 24px; height: 24px; border: 3px solid rgba(255,255,255,0.4); border-left-color: #fff; border-radius: 50%; background: transparent; animation-name: rotate; animation-iteration-count: infinite; animation-duration: .6s; animation-timing-function: linear; position: relative; vertical-align: middle; }
  .spinner.small { width: 18px; height: 18px; }
  .spinner.dark { border-color: rgb(200, 209, 216); border-left-color: #4E28DB; }
  @keyframes rotate { from { transform: rotate(0); } to { transform: rotate(360deg); } }

/* Buttons */

.btn{ font-family: 'clarika-geo'; display: inline-block; border: #4E28DB 1px solid; border-radius: 8px; transition: .3s; cursor: pointer; text-decoration: none; transition: .2s; user-select: none; outline: none; position: relative; background: #4E28DB; color: #fff; }
  .btn.small{ display: block; }
  .btn *{ cursor: pointer; }
  .btn:hover{ background: #704df1; border-color: #704df1; }
  .btn.outlined{ background: #fff; border-color: #4E28DB; color: #4E28DB; }
    .btn.outlined:hover{ background: #704df1; border-color: #704df1; color: #fff; }
  .btn.o-red{ background: #fff; border-color: #B7424C; color: #B7424C; }
    .btn.o-red:hover{ background: #B7424C; border-color: #B7424C; color: #fff; }
  .btn.gray{ background: #E5E5EA; border-color: #E5E5EA; color: #333333; }
    .btn.gray:hover{ background: #C4C4D6; border-color: #C4C4D6; color: #333333; }
  .btn > span{ display: block; font-size: 16px; line-height: 1.3; padding: 8px 24px 10px 24px; }
    .btn.small > span{ font-size: 14px; line-height: 1.3; padding: 8px 12px 10px 12px; text-align: center; width: 100%; }
  .btn .spinner-wp{ opacity: 0; position: absolute; display: flex; left: 0; top: 0; align-items: center; justify-content: center; width: 100%; height: 100%; }
  .btn.loading .spinner-wp{ opacity: 1; }
  .btn.loading > span{ opacity: 0; }
  .btn .spinner{ width: 18px; height: 18px; }
  .btn-upload{ position: relative; overflow: hidden; }
   .btn-upload input[type="file"]{ font-size: 200px; position: absolute; z-index: 1; top: 0; left: 0; opacity: 0; cursor: pointer; }

/* Form fields */
.field{ border: #E9E9EA 1px solid; border-radius: 3px; font-family: 'clarika'; color: #303D72; display: inline-block; padding: 8px 14px; font-size: 16px; background: #fff; box-sizing: border-box; }
    .field[disabled]{ color: #666; }
    .field.full{ width: 100%; display: block; }
    .field:focus{ border-color: #C0C0DD; }
    .field.invalid, .invalid .field{ border: red 1px solid; }
    .field.disabled{ cursor: not-allowed; background: #eee; color: #666; }
    .field.small{ max-width: 80px; }
    .field.wp{ padding: 0; }
      .field.wp > input{ font-family: 'clarika'; color: #303D72; padding: 12px 18px; border: 0; display: block; width: 100%; font-size: 16px; }
  .error-msg{ color: #B7424C; font-family: 'clarika'; font-size: 14px; font-weight: 400; } 
  .field select{ border: 0; outline: none; width: 100%; font-family: 'clarika'; color: #303D72; width: calc(100% - 20px); background: transparent; font-size: 14px; display: block; -webkit-appearance: none; }
    .field.select{ padding: 0; display: flex; align-items: center; }
    .field.select select{ padding: 8px 14px; font-size: 16px; }
  .draft-toolbar{ border: 0 !important; padding: 0 !important; }
  .rdw-editor-wrapper{ box-sizing: border-box !important; }

/* Dialog */
.dialog{ display: block; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(127,133,150,0.6); z-index: 11; padding: 32px; overflow-y: auto; }
    .box{ max-width: 640px; margin: auto; }
      .box.large{ max-width: 1000px; margin: auto; }
      .box.medium{ max-width: 800px; margin: auto; }
    .box .heading{ display: flex; padding: 16px 24px 16px 48px; border-bottom: #EAEAEA 1px solid; }
      .box .heading.simple{ padding-bottom: 0; border-bottom: 0; }
    .box .heading .close{ margin-left: auto; color: #aaa; cursor: pointer; font-size: 36px; line-height: 1; padding-left: 48px; }
        .box .heading .close:hover{ color: #000; }
      .box .heading .title{ font-weight: 600; }
    .box .inner{ padding: 24px 48px; }
      .box .inner .message{ margin-bottom: 24px; background: #ddf0ff; padding: 16px 25px; border-radius: 3px; }
    .box .actions{ padding: 16px 48px; border-top: #EAEAEA 1px solid; display: flex; }
      .box .actions .btn{ width: auto; margin-right: 16px; }
      .box .actions .right{ margin-left: auto; }

/* Commons */

.header{ display: flex; padding: 18px 0; background: #fff; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05); }
    .header .width{ display: flex; width: 100%; align-items: center; }
    .header .action{ cursor: pointer; color: #333; font-size: 16px; margin-left: 32px; }
      .header .action:hover{ color: #4E28DB; }
    .header .right{ margin-left: auto; }
    .header img{ width: 32px; height: 32px; }
    .header .logo{ display: flex; align-items: center; }
        .header .logo span{ font-size: 24px; line-height: 1; margin-left: 8px; }
    .header .client-title{ margin-left: 12px; }
      .header .client-title .name{ font-size: 18px; line-height: 1; }
      .header .client-title .transaction{ font-size: 14px; line-height: 1; margin-top: 2px; }

.footer{ margin-top: 32px; }


.switch-wp{ display: flex; align-items: center; }
  .switch-wp label{ margin-left: 12px; }

/* Page heading */
.top{ display: flex; }
  .top .right{ margin-left: auto; }

/* Clients */

.clients{ border: #E9E9EA 1px solid; border-radius: 4px; overflow: hidden; }

.client-wp{ background: #fff; border-top: #E9E9EA 1px solid; }
  .client-wp.inactive{ opacity: .5; }
    .client-wp:first-child{ border-top: 0; }
    .client .todo{ border-left: #E9E9EA 1px solid; }
      .client .todo:hover{ background: #F8F7FB; cursor: pointer; }
    .client > *{ padding: 16px 24px; }
    .client .info{ display: grid; grid-template-columns: 32px 1fr; grid-gap: 16px; }
    .client .avatar{ transform: translateY(4px); }
      .client .avatar:hover{ opacity: .8; }
      .client .h4:hover{ opacity: .8; }

.client-header{ background: #EAEAED; display: flex; }
  .client-header .links{ display: flex; padding-left: 16px; }
  .client-header .name{ color: #fff; background: #999FB2; padding: 18px 32px; }
  .client-header .links a{ padding: 18px 0 16px 0; margin: 0 16px; border-bottom: transparent 2px solid; color: #303D72; }
      .client-header .links a:hover{ color: #000; }
      .client-header .links a.active{ border-color: #4E28DB; color: #4E28DB; }

.percentages{ padding: 24px 0; border-bottom: #E9E9EA 1px solid; }
  .percentages.editable{ cursor: pointer; }
    .percentages.editable:hover{ background: #f5f5f5; }
  .percentages .width{ display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 32px; }
  .percentages .bar{ height: 3px; background: #D6D6E0; border-radius: 10px; position: relative;  margin-top: 8px; overflow: hidden; }
  .percentages .fill{ position: absolute; top: 0; left: 0; height: 3px; background: #7D60EC; }


.edit-percentages > div{ border-top: #E9E9EA 1px solid; padding: 12px 0; display: flex; align-items: center; }
  .edit-percentages > div:first-child{ border-top: 0; padding-top: 0; }
  .edit-percentages > div:last-child{ padding-bottom: 0; }
  .edit-percentages input{ max-width: 100px; margin-left: auto; margin-right: 16px; }
/* Updating bar */
.updating-status{ position: fixed; top: 24px; right: 24px; width: 36px; height: 36px; display: flex; align-items: center; justify-content: center; background: #fff; border: #ddd 1px solid; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); border-radius: 100%; z-index: 1; }
  .updating-bar .line{ position: absolute; top: 0; left: 0; width: 20%; height: 2px; background: #FB651E; animation: 1.5s linear fading infinite; }

.categories-wp{ width: 100vw; overflow-x: auto; }
.category .title{ margin-bottom: 12px; font-family: 'clarika-dm'; }
.category .title:hover{ cursor: pointer; opacity: .7; }
.category .title.empty{ cursor: pointer; opacity: .5; }
  .category .title.empty:hover{ opacity: 1; }


/* Cards */
.card{ border: #E9E9EA 1px solid; background: #fff; border-radius: 8px; padding: 12px 24px; margin-bottom: 24px; cursor: pointer; }
  .card.inactive{ opacity: .5; }
  .card:hover{ box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1); border-color: #D5D5D5; }
  .card .inner{ display: grid; grid-template-columns: 32px 1fr; grid-gap: 12px; }
    .card .inner img{ display: flex; justify-content: center; }
  .card img{ width: 24px; height: auto; }
  .card .attachments{ margin-top: 8px; }
  .attachment-link{ font-size: 16px; color: #4E28DB; display: flex; align-items: center; margin-top: 4px; }
    .attachment-link:hover{ opacity: .8; }
    .attachment-link .icon{ font-size: 12px; margin-right: 6px; }
    .card .avatar{ transform: translateY(4px); }

.btn.add-item{ display: flex; width: 100%; align-items: center; justify-content: center; padding: 8px 24px; }

.manage-attachment{ display: flex; align-items: center; margin-top: 8px; color: #4E28DB; border: #E9E9EA 1px solid; padding: 4px 12px 6px 12px; border-radius: 8px; background: #fff; }
  .manage-attachment:hover{ border-color: #D5D5D5; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); }
  .manage-attachment .icon{ font-size: 12px; margin-right: 8px; transform: translateY(1px);  cursor: pointer; }
  .manage-attachment .name{ white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 120px;  cursor: pointer; }
  .manage-attachment .external{ margin-left: auto; color: #ccc; font-size: 12px; transform: translateY(1px); }
    .manage-attachment .external:hover{ color: #333; }
  
.responsible{ display: flex; align-items: center; }
  .responsible .name{ margin-left: 8px; }
  .responsible img{ width: 28px; height: auto; }

  .select-category .option{ display: flex; margin-top: 8px; align-items: center; color: #303D72; padding: 8px 12px; background: #f5f5f5; border-radius: 8px; }
    .select-category .option.active{ color: #fff; background: #4E28DB; }
  .select-category .icon{ font-size: 12px; width: 20px; }

@media (min-width: 960px){
  .mob{ display: none !important; }
}

@media (max-width: 960px){

  .pt-7{ padding-top: 32px; }

  .width{ padding-left: 24px; padding-right: 24px; }

  .caps{ font-size: 11px; }

  .dialog{ padding-bottom: 128px; }

  .header{ padding: 14px 0; }
    .header img{ width: 24px; height: 24px; }
    .header .logo span{ font-size: 18px; }
    .header .action{ font-size: 12px; margin-left: 16px; }

  .h2{ font-size: 20px; line-height: 1.3; }

  .desk{ display: none !important; }
  .grid-break{ grid-template-columns: 1fr; }

  .client-header{ padding-left: 24px; align-items: center; }
  .client-header .avatar{ width: 24px; height: 24px; font-size: 14px; }
  .client-header .links{ padding-left: 8px; }
  .client-header .name{ background: #999FB2; padding: 12px 12px; max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  .client-header .links a{ padding: 12px 0 10px 0; margin: 0 6px; font-size: 14px; }

  .percentages{ padding: 24px 0; }
    .percentages .width{ grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px; }

  .grid.categories-grid{ grid-template-columns: 1fr !important; }

  .card{ padding: 0; }
    .card .inner{ padding: 14px 18px; }
  .card .controls, .client .controls{ border-top: #E9E9EA 1px solid; background: #EAEAEA; padding: 10px 18px; display: flex; width: 100%; font-size: 14px; font-family: 'clarika-dm'; color: #696969; }
    .card .controls .icon, .client .controls .icon{ margin-left: 12px; color: #696969; }
    .card .controls .right, .client .controls .right{ margin-left: auto; }

  .box .heading{ padding: 4px 18px 4px 18px; }
    .box .heading h3{ font-size: 20px; height: 1.3; padding: 4px 0 4px 0 }
  .box .inner{ padding: 14px 18px; }
  .box .actions{ padding: 14px 18px; }
  .box .heading .close{ font-size: 30px; line-height: 1; }
  .box .h2{ font-size: 20px; line-height: 1.8; }
  
  .btn > span{ padding: 4px 18px 6px 18px; }

  .clients{ border: 0; background: transparent; }
  .client-wp{ background: #fff; border-top: 0; margin-top: 24px; }
    .client-wp:first-child{ margin-top: 0; }
    .client{ background: #fff; border: #E9E9EA 1px solid; border-radius: 8px; overflow: hidden; }
    .client .todo{ border-top: #E9E9EA 1px solid; border-left: 0; }
    .client > *{ padding: 14px 18px; }

}